// src/components/Blob.js
import React from 'react';

const Blob = ({ top, left, right, bottom, shape, alt, color, animated, zIndex }) => {
  const svgPath = () => {
    switch (shape) {
      case '1':
        return `<path fill=${color} d="M30.6,-37.5C42.1,-26.9,55.5,-19.5,58.7,-9.2C61.9,1.2,55,14.4,46,23.2C36.9,31.9,25.8,36.1,14.3,41.1C2.8,46,-9.1,51.7,-25.3,53C-41.6,54.3,-62.3,51.1,-69.2,39.7C-76.1,28.3,-69.2,8.6,-64.7,-10.4C-60.2,-29.5,-58,-48,-47.6,-58.8C-37.2,-69.7,-18.6,-72.8,-4.5,-67.5C9.6,-62.1,19.1,-48.1,30.6,-37.5Z" transform="translate(100 100)" />`;
      case '2':
        return `<path fill=${color} d="M41.8,-42.7C57.4,-36.8,75.3,-26.6,79.4,-12.7C83.4,1.3,73.5,19,63.6,37.2C53.7,55.4,43.7,74,31,73.6C18.3,73.2,2.9,53.8,-14.2,45C-31.3,36.2,-50.1,38,-64.2,29.4C-78.2,20.8,-87.5,1.9,-85.5,-16C-83.4,-33.9,-70,-50.7,-53.9,-56.5C-37.7,-62.3,-18.9,-57.1,-2.9,-53.7C13.1,-50.3,26.3,-48.6,41.8,-42.7Z" transform="translate(100 100)" />`;
      case '3':
        return `<path fill=${color} d="M44.8,-53.2C52.2,-47.2,48.1,-27.5,49.2,-10.3C50.3,6.9,56.4,21.5,54.7,38.3C53,55.2,43.5,74.3,27.6,83.4C11.7,92.5,-10.6,91.5,-22.5,79.5C-34.5,67.6,-36,44.6,-43.8,27.2C-51.6,9.7,-65.8,-2.2,-70.2,-18.5C-74.6,-34.7,-69.2,-55.4,-55.8,-60.3C-42.5,-65.3,-21.3,-54.5,-1.3,-53C18.7,-51.5,37.5,-59.3,44.8,-53.2Z" transform="translate(100 100)" />`;
      default:
        return `<path fill=${color} d="M25.9,-27.6C32.9,-25.1,37.3,-16.2,37.8,-7.5C38.3,1.2,34.9,9.7,31.5,20.5C28,31.4,24.5,44.5,14.8,53.8C5,63.1,-10.9,68.5,-22.6,63.6C-34.3,58.7,-41.7,43.5,-52.8,28.4C-64,13.3,-79,-1.6,-76.1,-12.6C-73.2,-23.6,-52.4,-30.7,-36.8,-31.7C-21.1,-32.7,-10.5,-27.6,-0.5,-27C9.5,-26.4,19,-30.2,25.9,-27.6Z" transform="translate(100 100)" />`;
    }
  };

  const blobStyle = {
    top,
    left,
    right,
    bottom,
    position: 'absolute',
    zIndex: zIndex || '0',
  };

  return (
    <div className={`blob ${animated ? 'animated' : ''}`} style={blobStyle}>
      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" alt={alt}>
        <g dangerouslySetInnerHTML={{ __html: svgPath() }} />
      </svg>
    </div>
  );
};

export default Blob;
