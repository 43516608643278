// src/components/Footer.js
import React from 'react';
import logoWhite from '../assets/images/logo--white.png';
import '../styles/footer.css';
import { SpeedInsights } from "@vercel/speed-insights/react"

const Footer = () => {
  return (
    <footer className="footer">
      <img height="60" src={logoWhite} alt="Vessel Coffee Collective Logo" />
      <div>@2023 Vessel Coffee Collective | All Rights Reserved</div>
      <div>Comments or Questions: <a className='footer__link' href="mailto:info@vesselcoffeeco.com">info@vesselcoffeeco.com</a></div>
      <SpeedInsights />
    </footer>
  );
};

export default Footer;
