// src/components/Navigation.js
import React from 'react';
import '../styles/nav.css';
import '../assets/fa/css/font-awesome.min.css';

const Navigation = () => {
  const toggleMenu = () => {
    const menu = document.querySelector('.nav__menu');
    menu.classList.toggle('active');
    const hamburger = document.querySelector('.hamburger');
    hamburger.classList.toggle('active');
  };

  return (
    <div>
      <div className="hello-bar">
        We are open Monday-Saturday (Closed Sunday) 7am to 3pm. Kitchen closes at 2:30 PM.
      </div>
      <nav className="navbar">
        <a href="/" className="logo">
          <span className="logo__text">Vessel Coffee</span>
        </a>
        <ul className="nav__menu">
          <a href="/" className="logo">
            <span className="logo__text">Vessel Coffee</span>
          </a>
          <a className='nav__email' href="mailto:info@vesselcoffeeco.com">
            <i className="fa fa-envelope"></i>
          </a>
          <li><a href="/">Home</a></li>
          <li><a href="/about">About</a></li>
          <li><a href="/menu">Menu</a></li>
          <li><a target="_blank" rel="noreferrer" href="https://app.squareup.com/gift/ML43WB4GPA713/order">Gift Cards</a></li>
          <li><a href="https://order.vesselcoffeeco.com/s/order">Order Now</a></li>
        </ul>
        <div className="hamburger" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;
