// src/pages/Menu.js
import React, { useEffect } from "react";
import Navigation from "../components/Navigation";
import Blob from "../components/Blob";
import Footer from "../components/Footer";
import MenuItem from "../components/MenuItem";
import "../styles/menu.css";
import "../styles/main.css";
import "../styles/nav.css";
import "../styles/footer.css";

import simpleLatte from "../assets/images/simple-latte.png";
import blta from "../assets/images/blta.webp";
import avocadoSmash from "../assets/images/avocado-toast.jpg";
import mediterraneanToast from "../assets/images/mediterranean-toast.jpg";
import parfait from "../assets/images/parfait.webp";
import croissant from "../assets/images/croissant.png";
import cinnamonRoll from "../assets/images/cinnamon-roll--square.jpeg";
import creamCheeseDanish from "../assets/images/cream-cheese-danish--square.jpeg";
import strawberryBeignet from "../assets/images/strawberry-beignet.png";
import cranberryOrangeScone from "../assets/images/cranberry-orange-scone.jpg";
import jewishAppleCake from "../assets/images/jewish-apple-cake.jpg";
import blueberryMuffin from "../assets/images/blueberry-muffin.jpg";
import darkChocolateBrownie from "../assets/images/dark-chocolate-brownie.jpg";
import chocolateChipCookie from "../assets/images/gf-chocolate-chip-cookie.jpeg";
import lemonPoundCake from "../assets/images/gf-lemon-pound-cake.jpeg"
import breakfastSandwich from "../assets/images/breakfast-sandwich.jpg";
import plainCroissant from "../assets/images/plain-croissant.jpg";
import vanillaBeignet from "../assets/images/vanilla-beignet.jpg";
import almondCroissant from "../assets/images/almond-croissant.jpg";
import chickenSandwich from "../assets/images/chicken-sandwich.png";

const Menu = () => {
  useEffect(() => {
    const filterButtons = document.querySelectorAll(
      ".filter-button-group button"
    );

    filterButtons.forEach((button) => {
      button.addEventListener("click", (event) => {
        const filter = event.target.getAttribute("data-filter");
        const section = event.target
          .closest(".filter-button-group")
          .getAttribute("data-section");
        const items = document.querySelectorAll(`#${section} .menu-item`);

        items.forEach((item) => {
          if (filter === "*" || item.classList.contains(filter.slice(1))) {
            item.style.display = "block";
          } else {
            item.style.display = "none";
          }
        });

        filterButtons.forEach((btn) => btn.classList.remove("active"));
        event.target.classList.add("active");
      });
    });
  }, []);

  return (
    <div id="app">
      <Navigation />
      <div className="menu__hero">
        <h1>Menu</h1>
        <Blob
          color="#ffffff40"
          shape="1"
          alt="Blob Shape 4"
          left="0"
          bottom="-30px"
          zIndex="4"
          animated={true}
        />
        <Blob
          color="#ffffff40"
          shape="2"
          alt="Blob Shape 1"
          top="10%"
          right="70%"
          zIndex="4"
          animated={true}
        />
        <Blob
          color="#ffffff40"
          shape="3"
          alt="Blob Shape 2"
          bottom="30%"
          right="10%"
          zIndex="4"
          animated={true}
        />
        <div
          className="shape-divider shape-divider--bottom"
          style={{ bottom: "-98px" }}
        >
          <div className="custom-shape-divider">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                fill="var(--lightBlue)"
                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                className="shape-fill"
              ></path>
            </svg>
          </div>
        </div>
      </div>

      <section className="menu">
        <div id="food" className="tab-content">
          <h2 className="menu__section-title">Food</h2>
          <div className="filter-button-group" data-section="food">
            <button className="text--dark active bg--white" data-filter="*">
              Show All
            </button>
            <button className="text--white bg--gold" data-filter=".vegetarian">
              Vegetarian
            </button>
            <button className="text--white bg--darkPink" data-filter=".vegan">
              Vegan
            </button>
            <button className="text--white bg--navy" data-filter=".dairy-free">
              Dairy Free
            </button>
            <button
              className="text--white bg--lightGreen"
              data-filter=".nut-free"
            >
              Nut Free
            </button>
            <button
              className="text--white bg--midGreen"
              data-filter=".gluten-sensitive"
            >
              Gluten Sensitive
            </button>
          </div>

          <p className="disclaimer">
            All foods are prepared in a kitchen that handles nuts, dairy, and
            gluten. Cross-contamination may occur. Please notify us of any
            allergies or dietary restrictions and we will do our best to
            accommodate. Gluten-free bread is available for sandwiches and
            toasts, and elements can be removed from any order.
          </p>
          <p className="disclaimer">
            * Items marked with a * are available for purchase under those dietary restrictions, 
            but require modification. 
            Please let the cashier know if you have any dietary restrictions.
          </p>
          <section className="menu-items" aria-labelledby="menu-item-title">
            <MenuItem
              price="$8.95"
              title="Breakfast Sandwich"
              description="Sausage, egg and cheese with chipotle aoili on a toasted snowflake bun. May substitute bacon/avocado. GF Available."
              image={breakfastSandwich}
              nutFree={true}
              glutenSensitive={true}
              glutenSensitiveAsterisk="To make Gluten Sensitive, replace snowflake roll with GF bread."
              vegetarian={true}
              vegetarianAsterisk="To make Vegetarian, remove meat or substitute with avocado."
            />
            <MenuItem
              price="$12.95"
              title="Candied BTLA"
              description="Our BLT Bliss features a perfect combination of maple-candied bacon, arugula, fresh tomato, creamy avocado and basil aioli on a toasted snowflake bun."
              image={blta}
              nutFree={true}
              glutenSensitive={true}
              glutenSensitiveAsterisk="To make Gluten Sensitive, replace snowflake roll with GF bread."
            />
            <MenuItem
              price="$8.95"
              title="Chicken Sandwich"
              description="A juicy chicken breast and thick sliced bacon, topped with melted cheddar cheese, fresh tomato slices, and basil aioli on a fluffy snowflake burger roll"
              image={chickenSandwich}
              glutenSensitive={true}
              glutenSensitiveAsterisk="To make Gluten Sensitive, replace snowflake roll with GF bread."
            />
            <MenuItem
              price="$8.95"
              title="Avocado Smash Toast"
              description="Creamy smashed avocado on crispy sourdough, topped with arugula, radish slices, and a sprinkle of everything bagel seasoning."
              image={avocadoSmash}
              vegetarian={true}
              vegan={true}
              dairyFree={true}
              nutFree={true}
              glutenSensitive={true}
              glutenSensitiveAsterisk="To make Gluten Sensitive, replace sourdough bread with GF bread."
            />
            <MenuItem
              price="$8.95"
              title="Mediterranean Garden Toast"
              description="Savoury sourdough toast layered with rich hummus, cucumber, tomato, and crumbled feta cheese, finished with a light sprinkle of cayenne and a balsamic glaze."
              image={mediterraneanToast}
              vegetarian={true}
              nutFree={true}
              glutenSensitive={true}
              glutenSensitiveAsterisk="To make Gluten Sensitive, replace sourdough bread with GF bread."
              vegan={true}
              veganAsterisk="To make Vegan, remove feta cheese."
            />
            <MenuItem
              price="$6.95"
              title="Berry Parfait"
              description="Creamy vanilla yogurt layered with crunchy granola, and topped with fresh strawberries and blueberries."
              image={parfait}
              vegetarian={true}
              glutenSensitive={true}
              nutFree={true}
            />
          </section>
        </div>

        <div id="drinks" className="tab-content" data-section="drinks">
          <h2 className="menu__section-title">Drinks</h2>
          <div className="filter-button-group" data-section="drinks">
            <button className="text--dark active bg--white" data-filter="*">
              Show All
            </button>
            <button className="text--white bg--gold" data-filter=".coffee">
              Coffee
            </button>
            <button className="text--white bg--darkPink" data-filter=".tea">
              Tea
            </button>
            <button className="text--white bg--navy" data-filter=".specialty">
              Specialty
            </button>
            <button className="text--white bg--lightGreen" data-filter=".other">
              Other
            </button>
          </div>

          <p className="disclaimer">
            <b>Current Flavors:</b> Vanilla, Mocha, Caramel, Lavender, Orange Cinnamon <br></br>
            <b>Current Milk Options:</b> Whole, Oat, Almond, Macadamia, Coconut
          </p>
          <section className="menu-items" aria-labelledby="menu-item-title">
  <MenuItem
    price="$5.50"
    title="Lavender Latte"
    description="A beautifully sweet floral latte, featuring our house-made lavender syrup. May be made with espresso or matcha"
    coffee={true}
    specialty={true}
    hideTags={true}
  />
  <MenuItem
    price="$5.95"
    title="Pumpkin Pie Latte"
    description="Indulge in the sweet blend of our house-made pumpkin pie syrup, your choice of milk, topped with optional whipped cream and a sprinkle of pumpkin pie spice."
    coffee={true}
    specialty={true}
    hideTags={true}
  />
  <MenuItem
    price="$6.55"
    title="Apple Chai-der"
    description="A refreshing mix of chai tea and apple cider, topped with optional whipped cream and a sprinkle of pumpkin pie spice."
    tea={true}
    specialty={true}
    hideTags={true}
  />
  <MenuItem
    price="$2.95 - $3.45"
    title="Drip Coffee"
    description="Freshly brewed coffee, offering a rich and balanced flavor, perfect for any time of the day."
    coffee={true}
    hideTags={true}
  />
  <MenuItem
    price="$3.50 - $3.95"
    title="Iced Coffee"
    description="Chilled and refreshing iced coffee, a perfect pick-me-up for warm days or when you need a cool refreshment."
    coffee={true}
    hideTags={true}
  />
  <MenuItem
    price="$3.25"
    title="Espresso"
    description="A strong and concentrated coffee brewed by forcing hot water through finely-ground coffee beans."
    coffee={true}
    hideTags={true}
  />
  <MenuItem
    price="$3.95"
    title="Cortado"
    description="A balanced espresso drink with an equal amount of warm milk to reduce the acidity."
    coffee={true}
    hideTags={true}
  />
  <MenuItem
    price="$3.55"
    title="Macchiato"
    description="Espresso marked with a small amount of steamed milk and foam for a strong, yet slightly softened, coffee experience."
    coffee={true}
    hideTags={true}
  />
  <MenuItem
    price="$4.25"
    title="Cappuccino"
    description="A classic coffee drink with equal parts espresso, steamed milk, and frothed milk."
    coffee={true}
    hideTags={true}
  />
  <MenuItem
    price="$4.50 - $5.25"
    title="Latte"
    description="A smooth and creamy coffee beverage made with espresso and steamed milk, ideal for those who enjoy a milder coffee flavor."
    coffee={true}
    hideTags={true}
  />
  <MenuItem
    price="$4.75 - $5.25"
    title="Chai Latte"
    description="A rich and creamy latte made with a blend of black tea, aromatic spices, and steamed milk."
    tea={true}
    hideTags={true}
  />
  <MenuItem
    price="$4.50 - $5.25"
    title="Matcha"
    description="A smooth and frothy latte made with high-quality matcha powder, offering a perfect balance of sweetness and earthy flavors."
    tea={true}
    hideTags={true}
  />
  <MenuItem
    price="$3.55"
    title="Americano"
    description="Rich espresso diluted with hot water, similar to black coffee in strength but different in flavor."
    coffee={true}
    hideTags={true}
  />
  <MenuItem
    price="$3.25"
    title="Earl Grey Tea"
    description="A classic blend of black tea infused with the distinctive flavor of bergamot orange."
    tea={true}
    hideTags={true}
  />
  <MenuItem
    price="$3.25"
    title="Hot Cinnamon Spice Tea"
    description="A warm and spicy blend, combining black tea with bold flavors of cinnamon and clove."
    tea={true}
    hideTags={true}
  />
  <MenuItem
    price="$3.25"
    title="Strawberry Kiwi Tea"
    description="A refreshing and fruity herbal blend, combining the sweet taste of strawberries with the tangy flavor of kiwi."
    tea={true}
    hideTags={true}
  />
  <MenuItem
    price="$3.25"
    title="Sencha Green Tea"
    description="A delicate green tea, known for its smooth, mild flavor and rich, grassy aroma, sourced directly from Japan."
    tea={true}
    hideTags={true}
  />
  <MenuItem
    price="$3.25"
    title="Peppermint Tea"
    description="A refreshing herbal tea made from pure peppermint leaves, perfect for a soothing and invigorating experience."
    tea={true}
    hideTags={true}
  />
  <MenuItem
    price="$3.75 - $4.25"
    title="Hot Chocolate"
    description="Rich and creamy hot chocolate made with our home-made chocolate sauce."
    other={true}
    hideTags={true}
  />
  <MenuItem
    price="$3.75 - $4.25"
    title="Chocolate Milk"
    description="Rich and creamy chocolate milk made in house, a delightful treat for all ages."
    other={true}
    hideTags={true}
  />
  <MenuItem
    price="$2.95"
    title="Coconut Water"
    description="Stay hydrated and refreshed with our natural Coconut Water, a healthy, electrolyte-rich beverage that's perfect for a post-workout refreshment or a natural energy boost."
    other={true}
    hideTags={true}
  />
  <MenuItem
    price="$5.95"
    title="Local Kombucha"
    description="Experience our refreshing, local kombucha in three vibrant flavors: Lavender Lemongrass, Strawberry Basil, and Hibiscus Lemondrop"
    other={true}
    hideTags={true}
  />
  <MenuItem
    price="$2.45"
    title="Sparkling Water (Mountain Valley)"
    description="Crisp and refreshing sparkling water sourced from Mountain Valley. Naturally pure and rich in minerals."
    other={true}
    hideTags={true}
  />
</section>
        </div>

        <div id="pastries" className="tab-content" data-section="pastries">
          <h2 className="menu__section-title">Pastries</h2>
          <div className="filter-button-group" data-section="pastries">
            <button className="text--dark active bg--white" data-filter="*">
              Show All
            </button>
            <button className="text--white bg--midGreen" data-filter=".gluten-free">
              Gluten Free
            </button>
            <button
              className="text--white bg--navy"
              data-filter=".dairy-free"
            >
              Dairy Free
            </button>
            <button className="text--white bg--lightGreen" data-filter=".nut-free">
              Nut Free
            </button>
            <button className="text--white bg--darkPink" data-filter=".vegan">
              Vegan
            </button>
          </div>

          <section className="menu-items" aria-labelledby="menu-item-title">
            <MenuItem
              price="$3.45"
              title="Croissant"
              description="A flaky and buttery classic French pastry, perfect for breakfast or an afternoon treat."
              nutFree={true}
              image={plainCroissant}
            />
            <MenuItem
              price="$3.85"
              title="Almond Croissant"
              description="Rich almond cream filled croissant, topped with sliced almonds for a sweet and nutty flavor."
              image={almondCroissant}
            />
            <MenuItem
              price="$3.85"
              title="Cinnamon Roll"
              description="A soft and gooey cinnamon roll topped with a sweet glaze, perfect for pairing with your favorite coffee."
              nutFree={true}
              image={cinnamonRoll}
            />
            <MenuItem
              price="$3.85"
              title="Cream Cheese Danish"
              description="Flaky Danish pastry with a cream cheese filling topped with a drizzle of icing."
              image={creamCheeseDanish}
            />
            <MenuItem
              price="$3.85"
              title="Vanilla Beignet"
              description="Light and airy beignets filled with a sweet vanilla cream, dusted with powdered sugar."
              nutFree={true}
              image={vanillaBeignet}
            />
            <MenuItem
              price="$5.50"
              title="Cranberry-Orange Scone"
              description="A moist and flavorful scone infused with tangy cranberries and zesty orange, suitable for gluten-free and vegan diets."
              glutenFree={true}
              vegan={true}
              dairyFree={true}
              image={cranberryOrangeScone}
            />
            <MenuItem
              price="$4.50"
              title="Chocolate Chip Cookie"
              description="A delicious extra large gluten free chocolate chip cookie."
              glutenFree={true}
              image={chocolateChipCookie}
            />
            <MenuItem
              price="$5.50"
              title="Lemon Pound Cake"
              description="Tender and moist Lemon Pound Cake. Gluten and Dairy Free."
              glutenFree={true}
              dairyFree={true}
              image={lemonPoundCake}
            />
            <MenuItem
              price="$5.50"
              title="Brownie"
              description="Rich and chocolatey brownie that's gluten-free, dairy-free for a decadent guilt-free indulgence."
              glutenFree={true}
              dairyFree={true}
              image={darkChocolateBrownie}
            />
          </section>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Menu;
