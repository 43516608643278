// src/pages/About.js
import React from 'react';
import Navigation from '../components/Navigation';
import Blob from '../components/Blob';
import Footer from '../components/Footer';
import vibeCoffeeBar from '../assets/images/vibe-coffee-bar-wide.jpg';
import avocadoSmash from '../assets/images/avocado-smash.webp';
import svgQuoteIcon from '../assets/images/svg-quote-icon.svg'
import '../styles/about.css';

const reviews = [
  {
    text: "Absolutely love this place! Very excited about this new addition & can't wait to go back. Very reasonably priced. The lattes are great. Everyone was so super sweet. It's a quiet, relaxing environment to get some work done as well.",
    author: "Emily Sims"
  },
  {
    text: "The most perfect coffee shop!It feels so welcoming walking into this cafe, chatting with the friendly baristas and relaxing/working in the light and airy space - there's plenty of sunlight and seating. Great vibes all around and I look forward to each time I come in.",
    author: "Maria Matysik"
  },
  {
    text: "Lovely new coffee shop! Staff is friendly, atmosphere is inviting and relaxing, and food and drinks are awesome. Loved my vanilla oat latte and the breakfast melt was great! Excited to keep coming back.",
    author: "Julie Whalen"
  }
];

const About = () => {
  return (
    <div id="app" className="page--about">
      <Navigation />
      <section id="about__hero" className="about__hero">
        <div className="section--inner">
          <h1 class="about__hero__title">About Vessel Coffee Collective</h1>
        </div>
        <Blob color="#798A4240" shape="1" alt="Blob Shape 4" left="0" bottom="-30px" animated={true} />
        <Blob color="#F9C3B540" shape="2" alt="Blob Shape 1" top="10%" right="70%" animated={true} />
        <Blob color="#c7dce240" shape="3" alt="Blob Shape 2" bottom="30%" right="10%" animated={true} />
      </section>

      <section className="about__space">
        <div className="shape-divider shape-divider--top" style={{ top: '-98px' }}>
          <div className="custom-shape-divider shape--flipX">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path fill="var(--gold)"
                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                className="shape-fill"></path>
            </svg>
          </div>
        </div>
        <div className="section--inner">
          <div className="about__space__wrapper">
            <div className="about__space__text">
              <h2>Our Space</h2>
              <p>
                Vessel Coffee Collective is a lively community hub for coffee and connection. It's ideal for various occasions - from first dates to focused work sessions. Our café offers a welcoming space for engaging chats, serene contemplation, or productive work. We’re all about embracing community spirit and creating memorable moments with great coffee.
              </p>
            </div>
            <div className="about__space__image">
              <img src={vibeCoffeeBar} alt="Coffee and Food" />
            </div>
          </div>
        </div>
        <div className="shape-divider shape-divider--bottom" style={{ bottom: '-98px' }}>
          <div className="custom-shape-divider">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path fill="var(--gold)"
                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                className="shape-fill"></path>
            </svg>
          </div>
        </div>
      </section>
      <section className="about__coffee-and-food">
        <div className="section--inner">
          <div className="about__coffee-and-food__wrapper">
            <div className="about__coffee-and-food__image">
              <img src={avocadoSmash} alt="Coffee and Food" />
            </div>
            <div className="about__coffee-and-food__text">
              <h2>Our Coffee and Food</h2>
              <p>
                Vessel Coffee Collective combines a passion for top-quality coffee with a diverse, 
                inclusive menu. We offer a range of locally roasted coffees, from classic brews to 
                specialty drinks with house-made syrups. Our food menu caters to all, featuring 
                gluten-free, vegan, and vegetarian options. From toasts and sandwiches to delicious salads, 
                we ensure delicious choices for every dietary need and preference.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="about__reviews bg--lightBlue">
  <div className="custom-shape-divider shape-divider--top">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill" fill="#FFFFFF"></path>
    </svg>
  </div>
  <div className="section--inner">
    <h2 className="text--darkGreen">What Our Customers Say</h2>
    <p className="text--midGreen">Discover the experiences that make Vessel Coffee Collective a favorite spot for coffee enthusiasts.</p>
    <div className="reviews__list">
      {reviews.map((review, index) => (
        <div key={index} className={`review bg--white`}>
          <div className="review__text">
            <div className='quote-icon'>
              <img alt="" src={svgQuoteIcon}></img>
            </div>
            <p>{review.text}</p>
          </div>
          <div className='review__author'>
            <p className="text--midGreen">{review.author}</p>
          </div>
        </div>
      ))}
    </div>
    <div class="reviews__button-wrapper">
    <a href="https://www.google.com/maps/place/Vessel+Coffee+Collective/@39.9816344,-75.129849,17z/data=!4m8!3m7!1s0x89c6c94c1a0a3a75:0xbf40007fbecdcf61!8m2!3d39.9816303!4d-75.1272687!9m1!1b1!16s%2Fg%2F11vk92nphb?hl=en&entry=ttu" target="_blank" rel="noreferrer" className="reviews-cta cta cta--primary">Read All Google Reviews</a>
    </div>
  </div>
</section>
      <section className="about__visit">
        <div className="shape-divider shape-divider--top" style={{ top: '-98px' }}>
          <div className="custom-shape-divider shape--flipX">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path fill="var(--darkPink)"
                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                className="shape-fill"></path>
            </svg>
          </div>
        </div>
        <div className="section--inner">
          <h2>Visit Us</h2>
          <p>Vessel Coffee Collective is located at 2401 Frankford Ave, Philadelphia. Come and experience our unique approach to coffee!</p>
        </div>
        <div className="shape-divider shape-divider--bottom" style={{ bottom: '-98px' }}>
          <div className="custom-shape-divider">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path fill="var(--darkPink)"
                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                className="shape-fill"></path>
            </svg>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default About;
