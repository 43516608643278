import React, { useState, useRef, useEffect } from 'react';

const MenuItem = ({
  price,
  title,
  description,
  image,
  vegetarian,
  vegetarianAsterisk,
  vegan,
  veganAsterisk,
  dairyFree,
  dairyFreeAsterisk,
  nutFree,
  nutFreeAsterisk,
  glutenSensitive,
  glutenSensitiveAsterisk,
  glutenFree,
  glutenFreeAsterisk,
  coffee,
  coffeeAsterisk,
  tea,
  teaAsterisk,
  specialty,
  specialtyAsterisk,
  other,
  otherAsterisk,
  hideTags = false
}) => {
  const [hoveredTag, setHoveredTag] = useState(null);
  const [tooltipStyle, setTooltipStyle] = useState({});
  const tagRef = useRef(null);
  const tooltipRef = useRef(null);

  const categories = [
    { name: 'vegetarian', value: vegetarian, asterisk: vegetarianAsterisk },
    { name: 'vegan', value: vegan, asterisk: veganAsterisk },
    { name: 'dairy-free', value: dairyFree, asterisk: dairyFreeAsterisk },
    { name: 'nut-free', value: nutFree, asterisk: nutFreeAsterisk },
    { name: 'gluten-sensitive', value: glutenSensitive, asterisk: glutenSensitiveAsterisk },
    { name: 'gluten-free', value: glutenFree, asterisk: glutenFreeAsterisk },
    { name: 'coffee', value: coffee, asterisk: coffeeAsterisk },
    { name: 'tea', value: tea, asterisk: teaAsterisk },
    { name: 'specialty', value: specialty, asterisk: specialtyAsterisk },
    { name: 'other', value: other, asterisk: otherAsterisk }
  ].filter(category => category.value);

  useEffect(() => {
    if (hoveredTag && tagRef.current && tooltipRef.current) {
      const tagRect = tagRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;

      let right = tagRect.right;
      if (right + tooltipRect.width > viewportWidth) {
        right = viewportWidth - tooltipRect.width - 10; // 10px padding from screen edge
      }
      if (right < 100) {
        right = 10; // 10px padding from screen edge
      }
      setTooltipStyle({
      });
    }
  }, [hoveredTag]);

  return (
    <article className={`menu-item ${categories.map(c => c.name).join(' ')}`} aria-label={title}>
      <div className="menu-item-wrapper">
        <div className="menu-item-image-wrapper">
          <div className="menu-item-tags">
            {!hideTags && (
              <>
                <span className="menu-item-price" aria-label={`Price ${price}`}>{price}</span>
                <div>
                  {categories.map(category => (
                    <span 
                      key={category.name} 
                      ref={tagRef}
                      className={`tag ${category.name}`} 
                      aria-label={`${category.name.replace('-', ' ')}${category.asterisk ? ' with asterisk' : ''}`}
                      onMouseEnter={() => category.asterisk && setHoveredTag(category.name)}
                      onMouseLeave={() => setHoveredTag(null)}
                    >
                      {category.name === 'gluten-sensitive' ? 'G' : category.name.charAt(0).toUpperCase()}
                      {category.asterisk ? '*' : ''}
                      {hoveredTag === category.name && category.asterisk && (
                        <div 
                          ref={tooltipRef}
                          className="asterisk-tooltip"
                          style={tooltipStyle}
                        >
                          {category.asterisk}
                        </div>
                      )}
                    </span>
                  ))}
                </div>
              </>
            )}
          </div>
          {image && <img src={image} alt={title} className="menu-item-image" />}
        </div>
        <div className="menu-item-details">
          <h3 id={`menu-item-title-${title.toLowerCase().replace(/\s/g, '-')}`} className="menu-item-title">
            {title}
          </h3>
          <p className="menu-item-description">
            {description}
          </p>
        </div>
        <a href="https://order.vesselcoffeeco.com/s/order" className="cta cta--small order-cta cta--secondary menu-item-cta" aria-label={`Order ${title} now`}>
          Order Now
        </a>
      </div>
    </article>
  );
};

export default MenuItem;